<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        Thermite reactions are highly exothermic reactions between a metal oxide and a fine metal
        powder. The exothermic nature of the reaction is used for welding metal parts such as
        railway tracks. One such reaction is:
      </p>

      <p class="mb-5 pl-10">
        <chemical-latex content="Fe2O3(s) + 2 Al(s) -> Al2O3(s) + 2 Fe(s)" />
      </p>

      <p class="mb-6">
        If the surroundings absorb
        <number-value :value="qsurr" unit="\text{kJ/mol}" />
        of heat due to the reaction, determine
        <stemble-latex content="$\Delta\text{S}_{\text{univ}}$" /> for the reaction at 298 K using
        the table of data provided
        <a
          href="https://openstax.org/books/chemistry-2e/pages/g-standard-thermodynamic-properties-for-selected-substances"
          rel="noopener noreferrer"
          target="_blank"
          >here</a
        >.
      </p>

      <calculation-input
        v-model="inputs.deltaSuniv"
        class="mb-2"
        prepend-text="$\Delta\text{S}_{\text{univ}}:$"
        append-text="$\text{J K}^{-1}\text{mol}^{-1}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question295',
  components: {
    CalculationInput,
    NumberValue,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        deltaSuniv: null,
      },
    };
  },
  computed: {
    qsurr() {
      return this.taskState.getValueBySymbol('qsurr').content;
    },
  },
};
</script>
